<template>
    <div class="phone-book-page">
        <div class="title">
            校园电话簿
        </div>
        <div class="search-div">
            <el-button type="primary" size="small" @click="openAddDialog(false)">添加电话</el-button>
            <div style="display: flex;">
               <el-input size='medium' v-model="querySelect" style="margin-right: 10px;"></el-input>
               <el-button type="primary" size="small" @click="searchList">搜索</el-button>
            </div>
        </div>

        <div class="table-container">
            <el-table border :data="phoneBookList">
                <el-table-column prop="name" align="center" label="名称">
                </el-table-column>
                <el-table-column prop="phone" align="center" label="电话">
                </el-table-column>
                <el-table-column prop="typeName" align="center" label="分类">
                </el-table-column>
                <el-table-column align="center" label="创建时间">
                     <template slot-scope="scope">
                        {{scope.row.create_time | datefmt('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column prop="更新时间" align="center" label="更新时间">
                    <template slot-scope="scope">
                        {{scope.row.update_time | datefmt('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                 <el-table-column prop="update_user_name" align="center" label="最后更新者">
                    <template slot-scope="scope">
                        {{scope.row.update_user_name || scope.row.create_user_name}}
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="scope">
                        <span>
                         <el-button type="text" size="small" style="margin-right: 10px" @click="openAddDialog(true, scope.row)">编辑</el-button>
                         </span>
                         <span>
                             <el-popconfirm
                                confirmButtonText='好的'
                                cancelButtonText='不用了'
                                icon="el-icon-info"
                                iconColor="red"
                                @onConfirm="deletePhoneBook(scope.row.id)"
                                title="确定删除该电话吗？">
                                <el-button slot="reference" type="text" size="small">删除</el-button>
                            </el-popconfirm>
                         </span>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="page-pageination" background layout="prev, pager, next" :total="total" :page-size="size"
            :current-page.sync="page" @current-change="getPhoneBookList">
            </el-pagination>
        </div>

        <el-dialog :title="`${isEdit ? '编辑' : '新增'}电话`" class="add-dialog" :close-on-click-modal="false"
             width="400px" :visible.sync="addPhoneBookDialog">
            <el-form :model="addPhoneBookData" :rules="rules" ref="addPhoneBookForm" label-width="78px">
                <el-form-item label="名称" prop='name'>
                    <el-input v-model="addPhoneBookData.name" size="medium"></el-input>
                </el-form-item>
                <el-form-item label="电话号码" prop="phone">
                    <el-input size='medium' v-model="addPhoneBookData.phone"></el-input>
                </el-form-item>
                <el-form-item label="选择分类">
                    <el-select size='medium' v-model="addPhoneBookData.typeId" clearable placeholder="请选择">
                        <el-option
                        v-for="item in phoneBookTypeList"
                        :key="item.type_id"
                        :label="item.type_name"
                        :value="item.type_id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addPhoneBookDialog = false">取 消</el-button>
                <el-button type="primary" @click="sureSavePhoneBook('addPhoneBookForm')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>

export default {
    name: 'PhoneBook',
    data () {
        return {
            // 验证规则
            rules: {
                name: [
                    { required: true, message: '请输入姓名', trigger: 'change' }
                ],
                phone: [
                    { required: true, message: '请输入电话号码', trigger: 'change' }
                ]
            },

            // 电话簿列表
            phoneBookList: [],
            page: 1,
            size: 10,
            total: 0,
            querySelect: '',

            // 新增/编辑数据
            addPhoneBookData: {
                name: '',
                phone: '',
                typeId: ''
            },
            addPhoneBookDialog: false,
            isEdit: false,

            search: []
        }
    },
    mounted () {
        this.getPhoneBookList()
        this.getPhoneTypeList()
    },
    methods: {
        // 获取分类列表
        getPhoneTypeList () {
            this.axios.post('/phone/book/type/list', {
                page: 1,
                size: 1000,
                search: ''
            }).then((res) => {
                this.phoneBookTypeList = res.data.list
            })
        },

        // 获取电话列表
        getPhoneBookList () {
            this.axios.post('/phone/book/list', {
                page: this.page,
                size: this.size,
                search: this.querySelect
            }).then((res) => {
                this.phoneBookList = res.data.list.map(item => {
                    item.typeName = this.phoneBookTypeList.filter(typeItem => {
                        return typeItem.type_id === item.type_id
                    })[0].type_name || '-'
                    return item
                })
                this.total = res.data.count
                console.log(res)
            })
        },

        searchList () {
            this.page = 1
            this.getPhoneBookList()
        },

        /**
         * 打开弹窗
         * @param isEdit 是否为编辑
         * @param phoneBookData 为编辑时，电话数据
         */
        openAddDialog (isEdit, phoneBookData) {
            this.isEdit = isEdit
            this.addPhoneBookDialog = true

            if (isEdit) {
                this.addPhoneBookData = {
                    bookId: phoneBookData.id,
                    name: phoneBookData.name,
                    phone: phoneBookData.phone,
                    typeId: phoneBookData.type_id || ''
                }
            } else {
                this.addPhoneBookData = {
                    name: '',
                    phone: '',
                    typeId: ''
                }

                this.$nextTick(() => {
                    this.$refs.addPhoneBookForm.clearValidate()
                })
            }

            console.log(phoneBookData)
        },

        /**
         * 删除电话
         * @param editPhoneBookId 删除ID
         */
        deletePhoneBook (editPhoneBookId) {
            this.axios.post('/phone/book/delete', {
                phone_book_id: editPhoneBookId
            }).then((res) => {
                if (res) {
                    this.getPhoneBookList()
                    this.$message.success('删除成功')
                }
            })
        },

        /**
         * 保存电话数据
         * @param formName 表单名称
         */
        sureSavePhoneBook (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    const requestUrl = this.isEdit ? '/phone/book/update' : '/phone/book/add'
                    const { name, phone, bookId, typeId } = { ...this.addPhoneBookData }
                    console.log(bookId)
                    this.axios.post(requestUrl, {
                        phone_book_id: bookId,
                        name: name,
                        phone: phone,
                        type_id: typeId || null
                    }).then((res) => {
                        console.log(res)
                        this.addPhoneBookDialog = false
                        this.$message.success(`成功${this.isEdit ? '编辑' : '添加'}电话`)
                        this.getPhoneBookList()
                    }).catch(() => {
                        // console.log(error)
                    })
                } else {
                    return false
                }
            })
        }
    }
}
</script>

<style lang="scss">
    .phone-book-page{
        .title{
            padding: 14px;
            // margin-bottom: 18px;
            border-bottom: 1px solid #dddddd;
            font-size: 28px;
        }

        .search-div{
            display: flex;
            justify-content: space-between;
            padding: 10px 20px;
            border-bottom: 1px solid #dddddd;
        }

        .table-container{
            margin: 10px 20px 0;
        }

        .page-pageination{
            padding: 10px 0;
            text-align: right;
        }

        .add-dialog{
            .el-input,.el-select{
                width: 240px;
            }

            .el-dialog__body{
                padding: 10px 20px;
            }
        }
    }
</style>
